import(/* webpackMode: "eager", webpackExports: ["AccidentSeverityByYear"] */ "/vercel/path0/components/charts/accident-severity-by-year.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["AccidentsByHour"] */ "/vercel/path0/components/charts/accidents-by-hour.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["AccidentsByYearByMonth"] */ "/vercel/path0/components/charts/accidents-by-year-by-month.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["AccidentsByYear"] */ "/vercel/path0/components/charts/accidents-by-year.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Fatalities"] */ "/vercel/path0/components/charts/fatalities.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Injuries"] */ "/vercel/path0/components/charts/injuries.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/components/common/ImageComponent.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["SmallContactForm"] */ "/vercel/path0/components/small-contact-form.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/node_modules/.pnpm/@sanity+next-loader@1.2.23_@sanity+types@3.74.1_@types+react@19.0.8__next@15.1.6_@babel+core@_aujcq7nu4hzxyvzgrt75wxfoj4/node_modules/@sanity/next-loader/dist/client-components/live-stream.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/node_modules/.pnpm/@sanity+next-loader@1.2.23_@sanity+types@3.74.1_@types+react@19.0.8__next@15.1.6_@babel+core@_aujcq7nu4hzxyvzgrt75wxfoj4/node_modules/@sanity/next-loader/dist/client-components/live.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/node_modules/.pnpm/next-sanity@9.8.54_@emotion+is-prop-valid@1.2.2_@sanity+client@6.27.2_@sanity+icons@3.5.7_rea_zkak2g3d2xp2s7topn6bhqudja/node_modules/next-sanity/dist/visual-editing/client-component.js");
;
import(/* webpackMode: "eager", webpackExports: ["__esModule","default"] */ "/vercel/path0/node_modules/.pnpm/next@15.1.6_@babel+core@7.26.0_react-dom@19.0.0_react@19.0.0__react@19.0.0/node_modules/next/dist/client/app-dir/link.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@15.1.6_@babel+core@7.26.0_react-dom@19.0.0_react@19.0.0__react@19.0.0/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/images/Accident-347.png");
